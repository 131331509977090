<template>
	<div class="my-container m-all-20">
		<!-- 头部 -->
		<myHeader :title="i18n.myNode" :height="height">
			<!-- 节点列表 -->
			<template slot="content">
				<div v-if="nodeList.length" class="node-list p-bottom">
					<div class="node-item max-bg" v-for="(item, index) in nodeList">
						<div class="node-info d-space-row justify-start">
							<span class="f-36 m-right">{{ item.name }}</span>
							<span class="f-32 col-main m-right-10">{{item.arcAmount.toFixed(2)}}</span>
							<span class="f-22 col-main">LELE</span>
							<span class="f-30 f-w col-main m-left-right-10">+</span>
							<span class="f-32 col-main m-right-10">{{item.usdtAmount.toFixed(2)}}</span>
							<span class="f-22 col-main">USDT</span>
						</div>
						<div class="node-time p-left-right">
							<span class="f-20">{{item.createTime}}</span>
						</div>
						<div class="node-detail d-around-row m-top">
							<div>
								<span class="f-22 col-g">{{i18n.under}}</span>
								<span class="f-26">{{item.amount * item.times}}</span>
							</div>
							<div>
								<span class="f-22 col-g">{{i18n.released}}</span>
								<span class="f-26">{{parseFloat(item.selfAmount+item.groupAmount+item.identifyAmount).toFixed(2)}}</span>
							</div>
							<div>
								<span class="f-22 col-g">{{i18n.daily}}</span>
								<span class="f-26">{{item.rewardRate}}%</span>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="empty d-center-row m-bottom">
					<span>—— {{i18n.none}} ——</span>
				</div>
			</template>
		</myHeader>
	</div>
</template>

<script>
import {
	myList
} from "@/api/node.js";

export default {
	data() {
		return {
			nodeList: []
		};
	},
	computed: {
		height() {
			let length = this.nodeList.length
			if (length > 0) {
				return length * (80 + 50)
			} else {
				return 60
			}
		},
		i18n() {
			return this.$t('index')
		}
	},
	created() {
		this.getList();
	},
	methods: {
		async getList() {
			const {
				err,
				res
			} = await myList();
			if (err === null) {
				this.nodeList = res;
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.my-container {
	.node-list {
		width: 644px;

		.node-item {
			width: 100%;
			height: 239px;
			background: url('../../assets/image/node-bg.png');
			box-sizing: border-box;

			&:not(:nth-last-child(1)) {
				margin-bottom: 50px;
			}

			.node-info {
				width: 100%;
				height: 87px;
				background: rgba(12, 116, 6, 0.812);
				box-sizing: border-box;
				padding: 20px 45px;
				align-items: baseline;
			}

			.node-detail {
				&>div {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;

					span:nth-child(1) {
						color: #9B9FB5;
					}
				}
			}
		}
	}

	.empty {
		width: 100%;
		height: 200px;
		font-size: 26px;
		color: #6E7CA8;
	}
}
</style>